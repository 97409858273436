//TODO: 暫定的な対応なので後でちゃんと整理すべき

const schoolsToShowLearningCardDev: string[] = ["lDtLeSQo4QoI74RBgBR0"]; //オカモト塾
const schoolsToShowLearningCardStg: string[] = ["zY9hOvnnFxonRYrKjoDA"]; //サービス事業者A
const schoolsToShowLearningCardProd: string[] = []; //学芸大関連

export const schoolsToShowLearningCard: string[] = [
  ...schoolsToShowLearningCardDev,
  ...schoolsToShowLearningCardStg,
  ...schoolsToShowLearningCardProd
];

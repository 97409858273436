
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { StudentPageType } from "@/store/modules/student";
import { Getter, State } from "@/store/helper";
import { Options, Vue } from "vue-class-component";
import { schoolsToShowLearningCard } from "@/temp/school";
import { School } from "../../../../app/src/entities/school";

@Options({
  components: {
    MButton,
    MIcon
  },
  props: {
    selectPage: String
  }
})
export default class MSPanelHeader extends Vue {
  selectPage!: StudentPageType;

  @State("school") school!: School | null;
  @Getter("isExclusiveSchoolAiUser")
  isExclusiveSchoolAiUser!: boolean;

  get canUseLearningCard(): boolean {
    return !!(
      this.school && schoolsToShowLearningCard.includes(this.school.ref.id)
    );
  }

  get isProfilePage(): boolean {
    return this.selectPage === "profile";
  }

  get isHistoryPage(): boolean {
    return this.selectPage === "history" || this.selectPage === "learning";
  }

  get isBadgePage(): boolean {
    return this.selectPage === "badge";
  }

  get isSubmissionsPage(): boolean {
    return this.selectPage === "submissions";
  }

  get isTodoPage(): boolean {
    return this.selectPage === "todo";
  }

  get isReflectionPage(): boolean {
    return this.selectPage === "reflection";
  }
}


import { Options, Vue } from "vue-class-component";
import firebase from "firebase/app";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MSHistoryPanel from "@/components/student/MSHistoryPanel.vue";
import MSLearningPanel from "@/components/student/MSLearningPanel.vue";
import MSMessagePanel from "@/components/student/MSMessagePanel.vue";
import MSSubmissionsPanel from "@/components/student/MSSubmissionsPanel.vue";
import MSTodoPanel from "@/components/student/MSTodoPanel.vue";
import MSReflectionPanel from "@/components/student/MSReflectionPanel.vue";
import MSAddReflectionModal from "@/components/student/MSReflectionAddModal.vue";
import MSEditReflectionModal from "@/components/student/MSReflectionEditModal.vue";
import MSDisplayReflectionModal from "@/components/student/MSReflectionDisplayModal.vue";
import MSProfilePanel from "@/components/student/MSProfilePanel.vue";
import MSBadgePanel from "@/components/student/MSBadgePanel.vue";
import MSEditModal from "@/components/student/MSEditModal.vue";
import MSTodoAddModal from "@/components/student/MSTodoAddModal.vue";
import MSTodoEditModal from "@/components/student/MSTodoEditModal.vue";
import MsReserveModal from "@/components/student/MsReserveModal.vue";
import MsCommentModal from "@/components/student/MsCommentModal.vue";
import MsAddPropertyModal from "@/components/student/MsAddPropertyModal.vue";
import MsEditPropertyModal from "@/components/student/MsEditPropertyModal.vue";
import { Breadcrumb } from "../components/MHeaderPanel.vue";
import {
  School,
  schoolCollectionKey,
  SchoolConfig,
  schoolConfigCollectionKey
} from "@/entities/school";
import { Classroom, classroomCollectionKey } from "@/entities/classroom";
import { Student, studentCollectionKey } from "@/entities/student";
import store from "@/store";
import { StudentPageType } from "@/store/modules/student";
import { getSchool, getSchoolConfig } from "@/api/school";
import { getClassroom } from "@/api/classroom";
import { CustomProperty } from "@/entities/custom_property";
import { fetchCustomPropertiesOfSchool } from "@/api/customProperty";
import { saveErrorLog } from "@/api/error";
import { Getter, State } from "@/store/helper";
import { Unit } from "@/entities/reflection";
import { getRole } from "@/api/auth";
import { Role } from "@/entities/role";
import { schoolsToShowLearningCard } from "@/temp/school";

type TodoData = {
  id: string;
  title: string;
  createdAt: string;
  checkedAt: string | null;
};

@Options({
  components: {
    MNavBar,
    MHeaderPanel,
    MSBadgePanel,
    MSHistoryPanel,
    MSLearningPanel,
    MSMessagePanel,
    MSProfilePanel,
    MSSubmissionsPanel,
    MSTodoPanel,
    MSReflectionPanel,
    MSEditModal,
    MsReserveModal,
    MsCommentModal,
    MsAddPropertyModal,
    MsEditPropertyModal,
    MSTodoAddModal,
    MSTodoEditModal,
    MSAddReflectionModal,
    MSEditReflectionModal,
    MSDisplayReflectionModal
  }
})
export default class StudentProfile extends Vue {
  breadcrumbs: Breadcrumb[] = [
    {
      text: "生徒一覧",
      link: "/student"
    }
  ];
  schoolId = "";
  classroomId = "";
  studentId = "";
  school: School | null = null;
  schoolConfig: SchoolConfig | null = null;
  classroom: Classroom | null = null;
  role: Role | null = null;
  customProperties: CustomProperty[] = [];
  selectedCustomPropertyId = "";
  isDisplayStudentEditModal = false;
  isDisplayTodoAddModal = false;
  isDisplayTodoEditModal = false;
  isDisplayReserveModal = false;
  isDisplayCommentModal = false;
  isDisplayAddPropertyModal = false;
  isDisplayEditPropertyModal = false;
  todoToEdit: TodoData | null = null;
  comment = "";
  isDisplayReflectionAddModal = false;
  isDisplayReflectionEditModal = false;
  isDisplayReflectionDisplayModal = false;
  selectedUnit: Unit | null = null;

  @State("pageType", "student") pageType!: StudentPageType;
  @State("classrooms") classrooms!: Classroom[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isExclusiveSchoolAiUser") isExclusiveSchoolAiUser!: boolean;

  //TODO: 暫定的な対応
  get canUseLearningCard(): boolean {
    return !!(
      this.school && schoolsToShowLearningCard.includes(this.school.ref.id)
    );
  }

  get selectedCustomProperty(): CustomProperty | null {
    if (this.selectedCustomPropertyId === "") {
      return null;
    }
    const matchProperties = this.customProperties.filter(
      property => property.ref.id === this.selectedCustomPropertyId
    );
    if (matchProperties.length === 0) {
      return null;
    }
    return matchProperties[0];
  }

  get student(): Student | null {
    const matchStudents = store.state.students.filter(
      s => s.ref.id === this.studentId
    );
    if (matchStudents.length === 0) {
      return null;
    }

    return matchStudents[0];
  }

  get inRecess(): boolean {
    if (!this.student) {
      return false;
    }

    if (this.student.data.recessTime && this.student.data.recessTime > 0) {
      return true;
    } else {
      return false;
    }
  }

  get studentName(): string {
    if (!this.student) {
      return "";
    }
    return this.student.data.name;
  }

  get canEditStudent(): boolean {
    if (this.isAdmin) return false;
    if (!this.student) return false;
    if (!this.school) return false;
    const _schoolId = this.student.ref.parent.parent?.parent.parent?.id;
    if (!_schoolId) return false;
    return _schoolId === this.school.ref.id;
  }

  openModal() {
    this.isDisplayStudentEditModal = true;
  }

  openTodoAddModal() {
    this.isDisplayTodoAddModal = true;
  }

  openReflectionAddModal() {
    this.isDisplayReflectionAddModal = true;
  }

  closeStudentEditModal() {
    this.isDisplayStudentEditModal = false;
  }

  closeTodoAddModal() {
    this.isDisplayTodoAddModal = false;
  }

  closeTodoEditModal() {
    this.isDisplayTodoEditModal = false;
  }

  openCommentModal(comment: string) {
    this.comment = comment;
    this.isDisplayCommentModal = true;
  }

  openEditPropertyModal(id: string) {
    this.selectedCustomPropertyId = id;
    this.isDisplayEditPropertyModal = true;
  }

  editTodo(todo: TodoData) {
    if (!this.canEditStudent) return;
    if (!todo) {
      alert("編集するTODO情報が取得できませんでした。");
      return;
    }
    this.todoToEdit = todo as TodoData;
    this.isDisplayTodoEditModal = true;
  }

  selectReflection(reflection: { type: "edit" | "display"; value: Unit }) {
    if (reflection.type === "edit") {
      if (!this.canEditStudent) return;
      if (reflection.value) {
        this.selectedUnit = { ...reflection.value };
        this.isDisplayReflectionEditModal = true;
      }
    } else if (reflection.type === "display") {
      if (reflection.value) {
        this.selectedUnit = { ...reflection.value };
        this.isDisplayReflectionDisplayModal = true;
      }
    } else {
      return;
    }
  }

  async created() {
    store.dispatch("student/setup");
    this.schoolId = this.$route.params.schoolId as string;
    this.classroomId = this.$route.params.classroomId as string;
    this.studentId = this.$route.params.studentId as string;
    const db = firebase.firestore();
    const schoolRef = db.collection(schoolCollectionKey).doc(this.schoolId);
    const schoolConfigRef = db
      .collection(schoolConfigCollectionKey)
      .doc(this.schoolId);
    const classroomRef = schoolRef
      .collection(classroomCollectionKey)
      .doc(this.classroomId);
    store.dispatch(
      "messagePanel/changeMessageStream",
      classroomRef.collection(studentCollectionKey).doc(this.studentId)
    );
    this.getCustomProperties(schoolRef);

    try {
      const [school, schoolConfig, classroom, role] = await Promise.all([
        getSchool(schoolRef),
        getSchoolConfig(schoolConfigRef),
        getClassroom(classroomRef),
        getRole(this.studentId)
      ]);
      if (school) {
        this.school = school;
      }
      if (schoolConfig) {
        this.schoolConfig = schoolConfig;
      }
      if (classroom) {
        this.classroom = classroom;
      }
      if (role) {
        this.role = role;
      }
    } catch (e) {
      alert(`生徒情報の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get student detail"
      );
      return;
    }
  }

  async getCustomProperties(schoolRef: firebase.firestore.DocumentReference) {
    try {
      const customProperties = await fetchCustomPropertiesOfSchool(
        schoolRef,
        this.isAdmin
      );
      this.customProperties = customProperties;
    } catch (e) {
      alert(`オリジナル情報の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get custom properties"
      );
    }
  }
}


import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MSQandaRegisterModal from "@/components/student/MSQandaRegisterModal.vue";
import { Classroom } from "@/entities/classroom";
import {
  Student,
  studentConfigCollectionKey,
  StudentGrade,
  StudentProperty
} from "@/entities/student";
import store from "@/store";
import {
  deleteStudent,
  fetchStudentConfig,
  transferStudent,
  updateStudent,
  updateStudentRecess
} from "@/api/student";
import { CustomProperty } from "@/entities/custom_property";
import dayjs from "dayjs";
import { saveErrorLog } from "@/api/error";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Getter, State } from "@/store/helper";
import { schoolCollectionKey } from "../../entities/school";
import { getClassRoomsBasedOnRole } from "@/api/classroom";
import { convertToDateFromUnixtime } from "@/utils/date";
import { externalServicesCollectionKey } from "@/entities/external_service";
import { validateQandaAPIResponse } from "@/api/qanda";
import { sortClassroomsById } from "@/utils/classroom";
import { Role } from "@/entities/role";
import { validateUniqueGmail, validateUniqueMicrosoftEmail } from "@/api/role";

type ClassSchedule = {
  weekday: string;
  start: string;
  end: string;
};

type EmailReceivers = {
  email: string;
};

@Options({
  components: {
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField,
    MTextArea,
    MSQandaRegisterModal
  },
  emits: ["close"],
  props: {
    student: Object,
    classrooms: Array,
    customProperties: Array,
    schoolId: String,
    role: Object
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  name = "";
  schoolId = "";
  studentLoginId = "";
  selectClassroomId = "";
  classrooms: Classroom[] = [];
  customProperties: CustomProperty[] = [];
  role?: Role;
  gmail = "";
  microsoftEmail = "";
  characteristic = "";
  studentId = "";
  password = "";
  inRecess = false;
  selectGrade: StudentGrade = "その他";
  grades: StudentGrade[] = [
    "小1",
    "小2",
    "小3",
    "小4",
    "小5",
    "小6",
    "中1",
    "中2",
    "中3",
    "高1",
    "高2",
    "高3",
    "その他"
  ];
  classSchedules: ClassSchedule[] = [];
  emailReceivers: EmailReceivers[] = [];
  propertyDatas: {
    property: CustomProperty;
    value: string;
  }[] = [];
  weekdays: string[] = ["月曜", "火曜", "水曜", "木曜", "金曜", "土曜", "日曜"];
  enteredEmail = [];
  isDisplayQandaRegisterModal = false;

  @State("baseDatas", "studentList") baseDatas!: {
    [key: string]: string | number | boolean;
  }[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isServiceProvider") isServiceProvider!: boolean;

  get classRooms(): Classroom[] {
    return sortClassroomsById(
      getClassRoomsBasedOnRole(
        this.isServiceProvider,
        this.classrooms,
        store.state.school!.ref.id
      )
    );
  }

  get selectClassroom(): Classroom | null {
    const matchClassrooms = this.classRooms.filter(
      item => item.ref.id === this.selectClassroomId
    );
    if (matchClassrooms.length === 0) {
      return null;
    }
    return matchClassrooms[0];
  }

  get recessStartDay(): string {
    if (!this.student.data.recessTime || this.student.data.recessTime <= 0) {
      return "";
    }
    return dayjs(this.student.data.recessTime * 1000)
      .locale("ja")
      .format("YYYY/MM/DD");
  }

  get unlinkSchoolAIAt(): string {
    if (
      !this.student.data.unlinkSchoolAIAt &&
      !!this.student.data.unlinkSchoolAI === true
    ) {
      return "";
    }
    return convertToDateFromUnixtime(this.student.data.unlinkSchoolAIAt!);
  }

  get relinkSchoolAIAt(): string {
    if (
      !this.student.data.relinkSchoolAIAt &&
      this.student.data.unlinkSchoolAI === false
    ) {
      return "";
    }
    return convertToDateFromUnixtime(this.student.data.relinkSchoolAIAt!);
  }

  get schoolLoginId(): string {
    if (!store.state.school) {
      return "";
    }
    return store.state.school.data.id;
  }

  get validName() {
    return this.name.length > 0;
  }

  get validClassroom() {
    return this.selectClassroom !== null;
  }

  get validStudentId() {
    let students: Student[];
    if (!this.isServiceProvider) {
      students = store.state.students;
    } else {
      students = store.state.students.filter(student => {
        const studentRef = student.ref;
        const path = studentRef.path;
        const pathSegments = path.split("/");
        const schoolId =
          pathSegments[pathSegments.indexOf(schoolCollectionKey) + 1];
        return schoolId === this.schoolId;
      });
    }

    if (
      students
        .filter(item => item.ref.id !== this.student.ref.id)
        .map(item => item.data.id)
        .includes(this.studentId)
    ) {
      return false;
    }
    return /^[0-9a-zA-Z]{3,}$/.test(this.studentId);
  }

  get validstudentLoginId() {
    if (!this.studentLoginId) return false;
    return (
      /^[\x21-\x7E]+$/.test(this.studentLoginId) &&
      String(this.studentLoginId).length >= 5
    );
  }

  get defaultLoginId(): string {
    return `${this.schoolLoginId}-${this.studentId}`;
  }

  get validPassword() {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(this.password);
  }

  get validGmail() {
    if (!this.gmail) return true;
    return this.validEmail(this.gmail);
  }

  get validMicrosoftEmail() {
    if (!this.microsoftEmail) return true;
    return this.validEmail(this.microsoftEmail);
  }

  get validClassSchedules() {
    for (const schedule of this.classSchedules) {
      if (schedule.weekday.length === 0) {
        return false;
      }
      if (!schedule.start.match(/^\d{2}:\d{2}$/g)) {
        return false;
      }
      if (!schedule.end.match(/^\d{2}:\d{2}$/g)) {
        return false;
      }
    }

    return true;
  }

  get validEmailReceivers() {
    for (const emailReceiver of this.emailReceivers) {
      if (!emailReceiver.email) {
        return false;
      }
      if (!this.validEmail(emailReceiver.email)) {
        return false;
      }
    }
    return true;
  }

  get validPropertyDatas() {
    for (const data of this.propertyDatas) {
      if (
        data.property.data.type === "select" &&
        data.value &&
        !data.property.data.choices.includes(data.value)
      ) {
        return false;
      }
    }
    return true;
  }

  get validData() {
    return (
      this.validName &&
      this.validClassroom &&
      this.validStudentId &&
      this.studentLoginId &&
      this.validPassword &&
      this.validClassSchedules &&
      this.validEmailReceivers &&
      this.validPropertyDatas &&
      this.validGmail &&
      this.validMicrosoftEmail
    );
  }

  get isLinkedToQanda() {
    return this.student.data.isLinkedToQanda;
  }

  async createNandeStudent() {
    if (
      this.student.data.isNandeUserCreated === true &&
      this.student.data.unlinkSchoolAI === false
    ) {
      alert("この生徒はNANDEに登録済みです");
      return;
    }
    if (this.student.data.recessTime && this.student.data.recessTime > 0) {
      alert("休会中の生徒はスクールAIに登録できません。");
      return;
    }
    const subData = {
      isNandeUserCreated: this.student.data.isNandeUserCreated ?? false,
      uidOfSchoolAI: this.student.data.nandeUserId ?? "",
      unlinkSchoolAI: this.student.data.unlinkSchoolAI ?? false
    };
    const userInfo = {
      schoolDocId: store.state.school!.ref.id,
      classroomDocId: this.student.ref.parent.parent!.id,
      studentDocId: this.student.ref.id,
      studentDocPath: this.student.ref.path,
      name: this.student.data.name,
      emailReceivers: this.student.data.emailReceivers,
      loginId: this.studentLoginId,
      password: this.password,
      ...subData
    };
    try {
      const createNandeUser = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("create_nande_user");
      store.commit(
        "START_LOADING",
        `スクールAIに生徒を作成中...(最大で30秒かかる場合があります)`
      );
      await createNandeUser(userInfo);
      store.commit("END_LOADING");
      alert("登録に成功しました。");
    } catch (e) {
      alert("登録に失敗しました。");
      store.commit("END_LOADING");
    }
    this.$router.go(0);
  }

  async retrySchoolAiLinkage() {
    const subData = {
      isNandeUserCreated: this.student.data.isNandeUserCreated ?? false,
      uid: this.student.data.nandeUserId ?? ""
    };
    const userInfo = {
      schoolDocId: store.state.school!.ref.id,
      classroomDocId: this.student.ref.parent.parent!.id,
      studentDocId: this.student.ref.id,
      studentDocPath: this.student.ref.path,
      name: this.student.data.name,
      emailReceivers: this.student.data.emailReceivers,
      loginId: this.studentLoginId,
      password: this.password,
      ...subData
    };
    try {
      const createNandeUser = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("retry_school_ai_linkage");
      store.commit(
        "START_LOADING",
        `スクールAIに生徒を連携中...(最大で30秒かかる場合があります)`
      );
      await createNandeUser(userInfo);
      store.commit("END_LOADING");
      alert(
        "再連携を実行しました。画面をリロードして連携されているか確認してください。"
      );
    } catch (e) {
      alert("連携に失敗しました。");
      store.commit("END_LOADING");
    }
    this.$router.go(0);
  }

  async unlinkSchoolAi() {
    if (this.student.data.nandeUserId === "") {
      alert("この生徒はスクールAIに連携されていません。");
      return;
    }
    // const subData = {
    //   isNandeUserCreated: this.student.data.isNandeUserCreated ?? false,
    //   uidOfSchoolAI: this.student.data.nandeUserId ?? ""
    // };
    // const userInfo = {
    //   schoolDocId: store.state.school!.ref.id,
    //   classroomDocId: this.student.ref.parent.parent!.id,
    //   studentDocId: this.student.ref.id,
    //   studentDocPath: this.student.ref.path,
    //   name: this.student.data.name,
    //   emailReceivers: this.student.data.emailReceivers,
    //   loginId: this.loginId,
    //   password: this.password,
    //   // studentRef: this.student.ref,
    //   ...subData
    // };
    try {
      const unlinkSchoolAi = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("unlink_school_ai");
      store.commit(
        "START_LOADING",
        `スクールAIとの連携を解除中...(最大で30秒かかる場合があります)`
      );
      const requestPayload = {
        schoolDocId: store.state.school!.ref.id,
        classroomDocId: this.student.ref.parent.parent!.id,
        studentDocId: this.student.ref.id,
        uidOfSchoolAI: this.student.data.nandeUserId
      };
      await unlinkSchoolAi({
        ...requestPayload
      });
      store.commit("END_LOADING");
      alert("連携を解除しました。");
    } catch (e) {
      alert("連携解除に失敗しました。");
      store.commit("END_LOADING");
    }
    this.$router.go(0);
  }

  async unlinkQanda() {
    const res = confirm("この生徒のQANDA連携を解除してもよろしいですか？");
    if (!res) {
      return;
    }
    if (this.student.data.isLinkedToQanda === false) {
      alert("この生徒はすでにQandaの連携が解除されています");
      return;
    }
    if (this.student.data.recessTime && this.student.data.recessTime > 0) {
      alert("休会中の生徒は実行できません。");
      return;
    }
    try {
      store.commit("START_LOADING", "変更保存中...");
      const qandaId = this.student.data.qandaUserId ?? null;
      if (!qandaId) {
        throw new Error("QandaIdが取得できません");
      }
      const unlinkStudent = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("unlink_student_from_qanda_account");

      const res = await unlinkStudent({ qandaId });

      validateQandaAPIResponse(res);

      // 念のためバリデーション
      if (
        res.data.res.qandaUniqueId !== qandaId ||
        res.data.res.registered === true
      ) {
        throw new Error("予期せぬエラーが発生しました。");
      }

      const db = firebase.firestore();
      const batch = db.batch();
      const studentRef = db.doc(this.student.ref.path);
      const data = {
        isLinkedToQanda: false
      };
      batch.set(studentRef, { ...data }, { merge: true });

      const externalServicesRef = studentRef
        .collection(externalServicesCollectionKey)
        .doc("qanda");
      batch.set(externalServicesRef, { ...data }, { merge: true });

      await batch.commit();

      alert("連携解除に成功しました。");
    } catch (e) {
      console.error(e.message);
      alert("連携解除に失敗しました。\n" + e.message);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  validEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  addClassSchedule() {
    this.classSchedules.push({
      weekday: "",
      start: "",
      end: ""
    });
  }

  addEmailReceiver() {
    this.emailReceivers.push({ email: "" });
  }

  deleteSchedule(index: number) {
    this.classSchedules.splice(index, 1);
  }

  deleteEmailReceiver(index: number) {
    if (this.emailReceivers[index].email) {
      const confirmRes = confirm(
        "【確認】一度削除したメールアドレスを復元することはできません。本当に、メールアドレスを削除する場合は、「変更を保存」を押してください。"
      );
      if (!confirmRes) {
        return;
      }
    }
    this.emailReceivers.splice(index, 1);
  }

  async changeRecess() {
    store.commit("START_LOADING", "更新中...");
    try {
      if (this.student.data.recessTime && this.student.data.recessTime > 0) {
        await updateStudentRecess(this.student.ref, 0);
      } else {
        await updateStudentRecess(
          this.student.ref,
          Math.floor(Date.now() / 1000)
        );
      }
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update student recess"
      );
      return;
    }

    this.$router.go(0);
  }

  translateErrorMessage(e: unknown) {
    if (!(e instanceof Error)) return "予期せぬエラーが発生しました。";
    const { message } = e;
    if (message === "Gmail already in use") {
      return "既に使われているGoogleアカウントです";
    } else if (message === "Microsoft Email already in use") {
      return "既に使われているMicrosoftアカウントです";
    } else return message;
  }

  async update() {
    if (
      !this.validData ||
      !store.state.role ||
      !store.state.school ||
      !this.selectClassroom
    ) {
      return;
    }

    if (this.inRecess) {
      alert(
        "休会中は生徒情報を更新することはできません。先に休会解除をしてください。"
      );
      return;
    }

    store.commit("START_LOADING", "変更保存中...");

    const classScheduleText = this.classSchedules.map(
      schedule => `${schedule.weekday} ${schedule.start} ~ ${schedule.end}`
    );

    const emailReceivers = this.emailReceivers.map(
      emailReceiver => emailReceiver.email
    );

    const properties: StudentProperty[] = this.propertyDatas.map(data => {
      let value: string | number;
      if (data.property.data.type === "number") {
        value = Number(data.value);
      } else {
        value = data.value;
      }
      return {
        id: data.property.ref.id,
        value
      };
    });

    try {
      const [isGmailUnique, isMicrosoftEmailUnique] = await Promise.all([
        (async () => {
          return !this.gmail
            ? Promise.resolve(true)
            : validateUniqueGmail(this.gmail, this.student.ref.id);
        })(),
        (async () => {
          return !this.microsoftEmail
            ? Promise.resolve(true)
            : validateUniqueMicrosoftEmail(
                this.microsoftEmail,
                this.student.ref.id
              );
        })()
      ]);

      if (!isGmailUnique) {
        throw new Error("Gmail already in use");
      }
      if (!isMicrosoftEmailUnique) {
        throw new Error("Microsoft Email already in use");
      }

      await updateStudent(
        this.student.ref,
        this.studentId,
        this.name.trim(),
        this.selectGrade,
        classScheduleText,
        emailReceivers,
        this.password,
        this.studentLoginId,
        properties,
        this.characteristic,
        this.gmail,
        this.microsoftEmail
      );
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`変更情報の保存に失敗しました\n\n${this.translateErrorMessage(e)}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update student"
      );
      return;
    }

    if (this.selectClassroomId !== this.student.ref.parent.parent!.id) {
      const confirmRes = confirm(
        "教室が変更されています。生徒の所属教室を変更すると変更先の教室の確認権限を持たないスタッフが生徒情報を確認できなくなります。\n本当に教室を変更しますか？\n\n【注意】\n学習室入室中の生徒の教室を変更すると入室中の学習記録が正常に保存されません。教室変更は生徒が学習室に入室している時間を避けて実施してください。"
      );
      if (!confirmRes) {
        return;
      }

      store.commit("START_LOADING", "教室変更中...");

      try {
        await transferStudent(this.student.ref, this.selectClassroomId);
        store.commit("END_LOADING");
      } catch (e) {
        store.commit("END_LOADING");
        alert(e);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to transfer student"
        );
        return;
      }
      this.$router.replace("/student");
    }

    this.$router.go(0);
  }

  async deleteStudent() {
    const confirmRes = confirm(
      "生徒を本当に削除しますか？一度削除した生徒を復元することはできません"
    );
    if (!confirmRes) {
      return;
    }
    store.commit("START_LOADING", "削除中...");
    try {
      await deleteStudent(this.student.ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`生徒の削除に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete student"
      );
      return;
    }
    this.$router.go(0);
    this.$router.replace("/");
  }

  close() {
    this.$emit("close");
  }

  qandaRegisterModalOpen() {
    this.isDisplayQandaRegisterModal = true;
  }

  qandaRegisterModalClose(isRegistrationDone: boolean) {
    if (isRegistrationDone) {
      this.$emit("close");
      this.$router.go(0);
      return;
    }
    this.isDisplayQandaRegisterModal = false;
  }

  async created() {
    this.name = this.student.data.name;
    this.studentId = this.student.data.id;
    this.selectClassroomId = this.student.ref.parent.parent!.id;
    this.selectGrade = this.student.data.grade;
    const newClassSchedules = this.student.data.classSchedules.map(schedule => {
      const split1 = schedule.split(" ~ ");
      const split2 = split1[0].split(" ");
      return {
        weekday: split2[0],
        start: split2[1],
        end: split1[1]
      } as ClassSchedule;
    });
    this.classSchedules = newClassSchedules;
    if (this.student.data.emailReceivers) {
      this.emailReceivers = this.student.data.emailReceivers.map(
        (emailReceiver: string) => ({ email: emailReceiver })
      ) as EmailReceivers[];
    }
    this.propertyDatas = this.customProperties.map(property => {
      if (!this.student.data.properties) {
        return {
          property,
          value: ""
        };
      }
      const matchDatas = this.student.data.properties.filter(
        item => item.id === property.ref.id
      );
      if (matchDatas.length === 0) {
        return {
          property,
          value: ""
        };
      } else {
        return {
          property,
          value: matchDatas[0].value.toString()
        };
      }
    });
    this.characteristic = this.student.data.characteristic ?? "";
    this.gmail = this.role?.data.gmail ?? "";
    this.microsoftEmail = this.role?.data.microsoftEmail ?? "";
    try {
      const studentConfig = await fetchStudentConfig(
        this.student.ref.parent
          .parent!.collection(studentConfigCollectionKey)
          .doc(this.student.ref.id)
      );
      this.password = studentConfig ? studentConfig.data.password : "";
      this.studentLoginId = studentConfig
        ? studentConfig.data.loginId ?? this.defaultLoginId
        : "";
      this.inRecess = this.student.data.recessTime
        ? this.student.data.recessTime > 0
        : false;
    } catch (e) {
      alert(`一部生徒情報の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get studentConfig"
      );
      return;
    }
  }
}
